import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { customStyles } from './Styles'
import { useFetchWrapper } from '../../services/fetch-wrapper'

const BaseSelect = ({useEffectDependencies, onChange, onLoaded, onError, selectFirst = true, initialValue = undefined, serviceUrl, valueName, labelName, ...rest}) => {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [selected, setSelected] = useState()
    //const fetchWrapper = useFetchWrapper();
    const fetchWrapper = useFetchWrapper()

    const localEffectDependencies = useEffectDependencies ? useEffectDependencies : []

    // useEffect(() => {
    //   if (isFunction(onLoaded)) {
    //     onLoaded(true)
    //   }
    // }, [])

    useEffect(() => {
      console.log('use effect triggered by localEffectDependencies')
      if ((localEffectDependencies.length === 0) || (localEffectDependencies.find(ld => ld !== undefined))) {
          populateDropDown()
      }
    }, localEffectDependencies)

    // const handleError = function (err) {
    //     console.warn(err);

    //     return new Response(JSON.stringify({
    //         code: 400,
    //         message: `Unexpected network error occured calling ${serviceUrl}`
    //     }));
    // };

    const onSelectedValueChange = (selectedOption) => {
      setSelected(selectedOption)

      if (isFunction(onChange)) {
        onChange(selectedOption)
      }
    }

    const populateDropDown = () => {
      //fetchWrapper.get(serviceUrl)
      //.then(console.log('GOT RESULTS!!!!'));

      //console.log(fetchWrapper.get);
      //console.log(fetchWrapper.get(serviceUrl));
      //console.log(serviceUrl);
      //console.log(serviceUrl);
      //const twat = fetchWrapper.get(serviceUrl)
      //console.log(twat);
      return fetchWrapper.get(serviceUrl).then((data) => {
        console.log(data);
        //const data = await response.json()
        var mapped = data.map(x => ({ value: x[valueName], label: x[labelName] }))
        const hasOptions = mapped && mapped.length > 0
        setOptions(mapped)
        if (selectFirst && hasOptions) {
          //setSelected(mapped[0])
          onSelectedValueChange(mapped[0])
        }
        else if (initialValue && hasOptions && !selected) {
          console.log('selectFirst', selectFirst)
          console.log('initialValue', initialValue)
          console.log('mapped', mapped)
          var selectedOption = mapped.find(option => {
            try {
              if (option.value.toString() === initialValue.toString() || (option.value.toString() === initialValue.value.toString()))
              {
                return true;
              }
              else {
                return false;
              }
            } catch {
              return false;
            }
          })
          console.log('selectedOption', selectedOption)
          if (selectedOption) {
            onSelectedValueChange(selectedOption)
          }
        }
        else {
          setSelected(null)
        }

        if (isFunction(onLoaded)) {
          
          onLoaded({hasOptions})
        }
        
        setLoading(false)
      });

      /*const token = await authService.getAccessToken()
      const response = await fetch(serviceUrl, {
          headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      }).catch(handleError)

      console.log('selectFirst', selectFirst)
      console.log('initialValue', initialValue)

      if (response.ok) {
          const data = await response.json()
          var mapped = data.map(x => ({ value: x[valueName], label: x[labelName] }))
          const hasOptions = mapped && mapped.length > 0
          setOptions(mapped)
          if (selectFirst && hasOptions) {
            //setSelected(mapped[0])
            onSelectedValueChange(mapped[0])
          }
          else if (initialValue && hasOptions && !selected) {
            console.log('selectFirst', selectFirst)
            console.log('initialValue', initialValue)
            console.log('mapped', mapped)
            var selectedOption = mapped.find(option => option.value.toString() === initialValue.toString())
            console.log('selectedOption', selectedOption)
            if (selectedOption) {
              onSelectedValueChange(selectedOption)
              //setSelected(selectedOption)
            }
          }
          else {
            setSelected(null)
          }

          if (isFunction(onLoaded)) {
            
            onLoaded({hasOptions})
          }
          
          setLoading(false)
      } else {
        console.log(response)
        setError(true)

        if (isFunction(onError)) {
          onError(response)  
        }
      }*/
    }

    return (
        <React.Fragment>
            {loading ? (<div></div>) : error ? (<div className="form-control danger">Error</div>) : (
                <Select
                    styles={customStyles}
                    options={options}
                    value={selected}
                    onChange={ onSelectedValueChange }
                    placeholder={rest.placeholder ? rest.placeholder : ''}
                    {...rest}
                /> 
            )}
        </React.Fragment >
    )
}

export default BaseSelect