import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SquareFill } from 'react-bootstrap-icons'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import WeeklySummaryPinnerRowRenderer from './WeeklySummaryPinnerRowRenderer'
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent'
//import ExpandHistoryHeader from './ExpandHistoryHeader'

const PropertySummary = ({innerRef : gridRef, PropertyData, isLoading}) => {
  //const gridRef = useRef()

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  const [defaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'text-center',
    headerComponentParams: centeredHeaderComponentParams()
  })

  const colIntegerTotalCellRenderer = (params) => {
    return {
      component: 'customPinnedRowRenderer',
      params: { format: 'integer' }
    }
  }

  const colPercentTotalCellRenderer = (params) => {
    return {
      component: 'customPinnedRowRenderer',
      params: { format: 'percent' }
    }
  }

  const getColumnDefs = () => {
    return [
      {
        field: 'PropertyName',
        headerName: "Address",
        minWidth: 240,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'LeasedPercent',
        headerName: "% Leased",
        minWidth: 120,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        valueFormatter: (params) => {
          if (!params.node.rowPinned) {
            return params.value + '%'
          }
          return ''
        },
        cellRendererSelector: colPercentTotalCellRenderer
      },
      {
        field: 'Leased',
        headerName: "Leased Units",
        minWidth: 120,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'PendingNew',
        headerName: "Pending New",
        minWidth: 120,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'PendingRenewal',
        headerName: "Pending Renewal",
        minWidth: 120,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'AvailableUnits',
        headerName: "Available Units",
        minWidth: 120,
        maxWidth: 130,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },

      {
        field: 'TotalUnits',
        headerName: "Total Units",
        maxWidth: 140,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'Renewed',
        headerName: "Renewal Leases",
        headerClass: 'previous-period-header status-renewed',
        maxWidth: 125,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'New',
        headerName: "New Leases",
        headerClass: 'status-new',
        minWidth: 120,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'Transfer',
        headerName: "Transfer Leases",
        headerClass: 'status-transfer',
        minWidth: 120,
        maxWidth: 120,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      },
      {
        field: 'Canceled',
        headerName: "Canceled Leases",
        headerClass: 'status-canceled',
        maxWidth: 125,
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        cellRendererSelector: colIntegerTotalCellRenderer
      }
    ]
  }
  const [columnDefs, setColumnDefs] = useState(getColumnDefs())
  

  const generatePinnedBottomData = (api, columnApi, ignoreFilter = false) => {
    // generate a row-data with null values
    let result = {};

    console.log('generatePinnedBottomData')
    console.log(columnApi)
    console.log(columnApi.getAllGridColumns())

    columnApi.getAllGridColumns().forEach(item => {
        result[item.colId] = null;
    });

    const totals = calculatePinnedBottomData(api, result, ignoreFilter);
    totals[0]['LeasedPercent'] = totals[0] && (Number(totals[0]['Leased']).toFixed(1) / Number(totals[0]['TotalUnits']).toFixed(1) * 100)

    return totals;
  }

  const calculatePinnedBottomData = (api, target, ignoreFilter) => {
      //console.log(target);
      //list of columns fo aggregation
      let columnsWithAggregation = ['Leased', 'PendingNew', 'PendingRenewal', 'AvailableUnits', 'TotalUnits', 'Renewed', 'New', 'Transfer', 'Canceled']
      columnsWithAggregation.forEach(element => {
        //console.log('element', element);

        if (ignoreFilter) {
          api.forEachNode((rowNode) => {
            // if(rowNode.index < 10){
            //   console.log(rowNode);
            // }
              if (rowNode.data[element])
                  target[element] += Number(rowNode.data[element].toFixed(1));
          });
        }
        else {
          api.forEachNodeAfterFilter((rowNode) => {
            //if(rowNode.index < 10){
              //console.log(rowNode);
            //}
            if (rowNode.data[element])
                target[element] += Number(rowNode.data[element].toFixed(1));
          });
        }

        if (target[element])
            target[element] = `${target[element].toFixed(1)}`;
      })
      //console.log(target);
      //target['athlete'] = 'Total';
      console.log(target)
      return [target];
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const LegendItem = ({color, text}) => {
    return (
      <div style={{ display: 'inline-flex', marginRight: '2rem'}} className="align-content-center">
        <div style={{ display: 'inline-block', height: '1.5rem', width: '1.5rem', backgroundColor: color, border: '1px solid grey', borderRadius: '5px' }}></div>
        <span className='ms-2'>{text}</span>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={PropertyData}
          rowSelection="none"
          //domLayout="autoHeight"
          paginationPageSize={30}
          columnDefs={columnDefs}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          //animateRows={true}
          defaultColDef={defaultColumnDefs}
          pagination={false}
          frameworkComponents={{
            customPinnedRowRenderer: WeeklySummaryPinnerRowRenderer,
            customLoadingOverLoad: CustomLoadingOverlay,
            //agColumnHeader: ExpandHistoryHeader
          }}
          getRowStyle={function (params) {
            if (params.node.rowPinned) {
              return {
                'font-weight': 'bold',
                'pointer-events': 'none',
              };
            }
            else {
              if (params.data.TotalUnits === params.data.Leased) {
                return {
                  'background-color': '#A9D08E'
                }
              } else if (params.data.PendingNew + params.data.PendingRenewal !== 0 
                && params.data.Leased + params.data.PendingNew + params.data.PendingRenewal === params.data.TotalUnits) {
                return {
                  'background-color': '#C9C9C9'
                }
              }
            }
          }}
          onRowDataChanged={(event) => {
            console.log('onRowDataChanged')
            console.log(event)
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))

            let items = []
            event.api.forEachNode(function(node) { 
                items.push(node.data);
            });

            console.log('model', event.api.getModel())
            

            console.log('items', items)
          }}
          onFilterChanged ={(event) => {
            console.log('onFilterChanged')
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
          }}
          onGridReady={(event) => { 
            console.log('onGridReady')

            //setGridApi(event.api)
            //setGridColumnApi(event.columnApi)
            console.log(event);
            event.api.sizeColumnsToFit()
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'Address',
                  sort: 'asc'
                }
              ]
            })
          }}
        >
        </AgGridReact>
      </div>
      <div style={{border: '1px solid var(--ag-border-color, #babfc7)'}} className="d-flex mt-3 p-2 justify-content-start">
        <LegendItem color="#A9D08E" text="100% Leased"/>
        <LegendItem color="grey" text="Pending 100% Leased"/>
        <LegendItem color="white" text="Opennings"/>
      </div>
    </React.Fragment>
  )
}

export default PropertySummary