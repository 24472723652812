import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Arrow90degRight, Download } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import WeeklySummaryPinnerRowRenderer from './WeeklySummaryPinnerRowRenderer'
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent'
//import ExpandHistoryHeader from './ExpandHistoryHeader'

const WeeklySummary = ({innerRef : gridRef, WeeklyData, UnitCount, Header, Headers, InitialHeaders, isLoading}) => {
  //const gridRef = useRef()
  //const loadPreviousIconRef = useRef(React.createRef());
  const preLeaseHeader = 'PreLease%'
  const ytdHeader = 'YTD%'

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  useEffect(() => {
    console.log('weeklySummaryData.Header changed')
    //gridRef
    //columnDefs
    var newColumnDefs = getColumnDefs()
    newColumnDefs.forEach(function (colDef, index) {
      console.log(colDef);
      if (colDef.colId == 'primary')
      {
        console.log('headers: ', Headers)
        if (Headers && Headers.length != 0) {
          colDef.headerName = Headers[0]
        }

        colDef.children.forEach(function (colDef, index) {
          if (colDef.field == 'PreLeasePercent')
          {
            if (Header) {
              colDef.headerName = `${preLeaseHeader} ${Header}`
            }
            else {
              colDef.headerName = preLeaseHeader
            }
          }
          if (colDef.field == 'YTD')
          {
            if (Header) {
              colDef.headerName = `${ytdHeader} ${Header}`
            }
            else {
              colDef.headerName = ytdHeader
            }
          }
        });
      } else if (colDef.colId == 'secondary') {
        if (Headers && Headers.length != 0) {
          colDef.headerName = Headers[1]
        }
      }
    });

    setColumnDefs(newColumnDefs)
  //}, [weeklySummaryData.Header])
//}, [Header, Headers])
  }, [WeeklyData])

// useEffect(() => {
//   console.log('weeklySummaryData.Headers changed')
//   //gridRef
//   //columnDefs
//   var newColumnDefs = getColumnDefs()
//   newColumnDefs.forEach(function (colDef, index) {
//     console.log(colDef);
//     if(colDef.colId == 'primary')
//     {
//       console.log(Headers[0])
//       if (Headers) {
//         colDef.headerName = Headers[0]
//       }
//     }
//   });

//   setColumnDefs(newColumnDefs)
// //}, [weeklySummaryData.Header])
// }, [Headers])

  function weekColumnComparator(valueA, valueB, nodeA, nodeB, isInverted) {
    const nodeAValue = nodeA.data['Week'];
    const nodeBValue = nodeB.data['Week'];

    console.log(nodeA, nodeB)

    if (nodeAValue == nodeBValue) return 0;
    return (nodeAValue > nodeBValue) ? 1 : -1;
  }

  const [defaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'text-center',
    headerComponentParams: centeredHeaderComponentParams()
  })

  const colIntegerTotalCellRenderer = (params) => {
    return {
      component: 'customPinnedRowRenderer',
      params: { format: 'integer' }
    }
  }

  const colPercentTotalCellRenderer = (params) => {
    return {
      component: 'customPinnedRowRenderer',
      params: { format: 'percent' }
    }
  }

  const getColumnDefs = () => {
    return [
      {
        colId: 'primary',
        headerName: InitialHeaders[0],
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center',
        marryChildren: true,
        children: [
          {
            field: 'Label',
            headerName: "Week",
            minWidth: 120,
            maxWidth: 140,
            minWidth: 240,
            pinned: 'left',
            lockPinned: true,
            comparator: weekColumnComparator,
            cellStyle: { textAlign: 'center' }
          },
          {
            field: 'Renewed',
            headerName: "Renewal Leases",
            headerClass: 'status-renewed',
            minWidth: 120,
            maxWidth: 120,
            filter: 'agNumberColumnFilter',
            cellStyle: { textAlign: 'center' },
            cellRendererSelector: colIntegerTotalCellRenderer
          },
          {
            field: 'New',
            headerName: "New Leases",
            headerClass: 'status-new',
            minWidth: 120,
            maxWidth: 120,
            filter: 'agNumberColumnFilter',
            cellStyle: { textAlign: 'center' },
            cellRendererSelector: colIntegerTotalCellRenderer
          },
          {
            field: 'Transfer',
            headerName: "Transfer Leases",
            headerClass: 'status-transfer',
            minWidth: 120,
            maxWidth: 120,
            filter: 'agNumberColumnFilter',
            cellStyle: { textAlign: 'center' },
            cellRendererSelector: colIntegerTotalCellRenderer
          },
          {
            field: 'Canceled',
            headerName: "Canceled Leases",
            headerClass: 'status-canceled',
            minWidth: 125,
            maxWidth: 125,
            filter: 'agNumberColumnFilter',
            cellStyle: { textAlign: 'center' },
            cellRendererSelector: colIntegerTotalCellRenderer
          },
          {
            field: 'Total',
            headerName: "Total Leases",
            minWidth: 120,
            maxWidth: 120,
            filter: 'agNumberColumnFilter',
            cellStyle: { textAlign: 'center' },
            cellRendererSelector: colIntegerTotalCellRenderer
          },
          {
            field: 'PreLeasePercent',
            headerName: preLeaseHeader,
            minWidth: 150,
            maxWidth: 150,
            filter: 'agNumberColumnFilter',
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => {
              if (!params.node.rowPinned) {
                return params.value + '%'
              }
              return ''
            },
            cellRendererSelector: colPercentTotalCellRenderer
          },
          {
            field: 'YTD',
            headerName: ytdHeader,
            minWidth: 120,
            maxWidth: 120,
            filter: 'agNumberColumnFilter',
            resizable: false,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => {
                if (!params.node.rowPinned) {
                    return params.value + '%'
                }
                return ''
            },
            cellRendererSelector: colPercentTotalCellRenderer
          }
        ]
      },
      {
        colId: 'secondary',
        resizable: false,
        headerName: InitialHeaders[1],
        cellStyle: {textAlign: 'center'},
        headerClass: 'text-center previous-period-header',
        marryChildren: true,
        children: [
          {
            field: 'PreviousPreLeasePercent',
            headerName: preLeaseHeader,
            headerClass: 'previous-period-header',
            minWidth: 150,
            maxWidth: 150,
            filter: 'agNumberColumnFilter',
            //cellStyle: { textAlign: 'center', paddingRight: '2.8rem' },
            cellStyle: { textAlign: 'center' },
            cellClass: 'previous-period-header',
            valueFormatter: (params) => {
              if (!params.node.rowPinned) {
                return params.value + '%'
              }
              return ''
            },
            cellRendererSelector: colPercentTotalCellRenderer/*,
            headerComponentParams: {
              template: '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal; display: inline-block; max-width: min-content; text-align: center; margin: auto;"></span>' + (loadPreviousIconRef.current.outerHTML ? loadPreviousIconRef.current.outerHTML.replace('<button', '<button ref="eCustomButton" onClick="agHandleClick(event)"') : '') +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                '  </div>' +
                '</div>'
            }*/
          },
          {
            field: 'PreviousYTD',
            headerName: "YTD%",
            minWidth: 120,
            maxWidth: 120,
            filter: 'agNumberColumnFilter',
            resizable: false,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => {
              if (!params.node.rowPinned) {
                return params.value + '%'
              }
              return ''
            },
            cellRendererSelector: colPercentTotalCellRenderer
          }
        ]
      },
      {
        colId: 'padding',
        resizable: false,
        headerName: ' ',
        marryChildren: true,
        children: [
          {
            colId: 'paddingChild',
            resizable: false,
            headerName: ' ',
            minWidth: 30,
            sortable: false,
            filter: false
          }
        ]
      }
    ]
  }
  const [columnDefs, setColumnDefs] = useState(getColumnDefs())
  

  const generatePinnedBottomData = (api, columnApi, ignoreFilter = false) => {
    // generate a row-data with null values
    let result = {};

    console.log('generatePinnedBottomData')
    console.log(columnApi)
    console.log(columnApi.getAllGridColumns())

    columnApi.getAllGridColumns().forEach(item => {
        result[item.colId] = null;
    });
    const totals = calculatePinnedBottomData(api, result, ignoreFilter);
    totals[0]['YTD'] = WeeklyData && WeeklyData.length && WeeklyData[WeeklyData.length - 1]['YTD']
    totals[0]['PreLeasePercent'] = UnitCount && Number(totals[0]['Total'] / UnitCount.toFixed(1) * 100)
    totals[0]['PreviousYTD'] = WeeklyData && WeeklyData.length && WeeklyData[WeeklyData.length - 1]['PreviousYTD']


    return totals;
  }

  const calculatePinnedBottomData = (api, target, ignoreFilter) => {
      //console.log(target);
      //list of columns fo aggregation
      let columnsWithAggregation = ['Renewed', 'New', 'Transfer', 'Canceled', 'Total', 'PreviousPreLeasePercent']
      columnsWithAggregation.forEach(element => {
        //console.log('element', element);

        if (ignoreFilter) {
          api.forEachNode((rowNode) => {
            // if(rowNode.index < 10){
            //   console.log(rowNode);
            // }
              if (rowNode.data[element])
                  target[element] += Number(rowNode.data[element].toFixed(1));
          });
        }
        else {
          api.forEachNodeAfterFilter((rowNode) => {
            //if(rowNode.index < 10){
              //console.log(rowNode);
            //}
            if (rowNode.data[element])
                target[element] += Number(rowNode.data[element].toFixed(1));
          });
        }

        if (target[element])
            target[element] = `${target[element].toFixed(1)}`;
      })
      //console.log(target);
      //target['athlete'] = 'Total';
      console.log(target)
      return [target];
  }

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const loadPrevious = useCallback(() => {
    console.log('load previous called')
  }, [])

  return (
    <React.Fragment>
      {/* <div className="d-none">
        <div ref={loadPreviousIconRef}>
          <Button color="white-outline" className="btn-sm" onClick={loadPrevious}><Arrow90degRight /></Button>
        </div>
      </div> */}
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={WeeklyData}
          rowSelection="none"
          //domLayout="autoHeight"
          paginationPageSize={30}
          columnDefs={columnDefs}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          //animateRows={true}
          defaultColDef={defaultColumnDefs}
          pagination={false}
          frameworkComponents={{
            customPinnedRowRenderer: WeeklySummaryPinnerRowRenderer,
            customLoadingOverLoad: CustomLoadingOverlay,
            //agColumnHeader: ExpandHistoryHeader
          }}
          getRowStyle={function (params) {
            if (params.node.rowPinned) {
              return {
                'font-weight': 'bold',
                'pointer-events': 'none',
            };
            }
          }}
          onRowDataChanged={ async (event) => {
            console.log('onRowDataChanged')
            console.log(event)
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))

            // console.log('model 2', event.api.getModel())

            // // let items = []
            // // event.api.forEachNode(function(node) { 
            // //   columnApi.getAllGridColumns().forEach(col => {
            // //     node.data[col.colId] = NULL;
            // //   });
        
            // // })

            // const formattedValues = []
            // const gridColumns = event.columnApi.getAllGridColumns()
            // event.api.forEachNode((rowNode) => {
            //   let target = {}
            //   for (let j = 0; j < gridColumns.length; j++) {
            //     const colA = gridColumns[j]

            //     if (rowNode.data[colA.colId]) {
            //       console.log(colA)
            //       target[colA.colId] = rowNode.data[colA.colId]
            //       if (colA.colDef.hasOwnProperty('valueGetter')) {
            //         console.log('rowNode', rowNode)
            //         target[colA.colId] = colA.colDef.valueGetter({ node: rowNode})
            //       }
            //     }
            //     else {
            //       target[colA.colId] = null
            //     }
            //   }
            //   formattedValues.push(target)
            // })

            // const gridColumns2 = event.columnApi.getAllGridColumns()
            // for (let i = 0; i < formattedValues.length; i++) {
            //   for (let j = 0; j < gridColumns2.length; j++) {
            //     const col = gridColumns2[j]
            //     console.log('WTF col', col)
            //     if (col.colDef.hasOwnProperty('cellRendererSelector')) {
            //       const ctrlsFactory = event.api.context.beanWrappers.beans.beanInstance.ctrlsFactory

            //       const userComponentFactory = event.api.context.beanWrappers.beans.beanInstance.userComponentFactory

            //       //console.log('ctrlsFactory', ctrlsFactory)
            //       //console.log('userComponentFactory', userComponentFactory)
            //       //console.log('cellRendererSelector', col.colDef.cellRendererSelector)
            //       //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance(col.colDef.cellRendererSelector.component))
            //       //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance('customPinnedRowRenderer'))
            //       //console.log('userComponentFactory.gottenInstance', userComponentFactory.getCellRendererDetails(col.colDef.cellRendererSelector))
            //       //console.log(col.context.contextParams.beanClasses)

            //       const compDetails = userComponentFactory.getCellRendererDetails(col.colDef, {...col.colDef.cellRendererSelector.params, value: formattedValues[i][col.colId] } )
            //       console.log('compDetails', compDetails)

            //       const [componentPromise] = await Promise.all([
            //         compDetails.newAgStackInstance()
            //       ])
            //       //const componentPromise = await compDetails.newAgStackInstance();
            //       console.log('componentPromise', componentPromise)

            //       if (componentPromise) {
            //         // componentPromise.then((result) => {
            //         //   //console.log('result', result)
            //         //   //console.log('innerHTML', result.reactElement.props.reactContainer.innerHTML)
            //         //   console.log('innerText', result.reactElement.props.reactContainer.innerText)
            //         //   value = result.reactElement.props.reactContainer.innerText

            //         //   target[col.colId] = value;
            //         // });

            //         //const result = await componentPromise
            //         //console.log('result', result)
            //         //console.log('innerHTML', result.reactElement.props.reactContainer.innerHTML)
            //         console.log('innerText', componentPromise.reactElement.props.reactContainer.innerText)
            //         formattedValues[i][col.colId] = componentPromise.reactElement.props.reactContainer.innerText;
            //       }


            //     }
            //   }
            // }

            // // event.api.forEachNode(async (rowNode) => {
            // //   let target = {}
            // //   for (const col in gridColumns) {
            // //   //gridColumns.forEach(async (col) => {

            // //     if (rowNode.data[col.colId]) {
            // //       console.log(col)
            // //       let value = rowNode.data[col.colId]
            // //       if (col.colDef.hasOwnProperty('valueGetter')) {
            // //         console.log('rowNode', rowNode)
            // //         value = col.colDef.valueGetter({ node: rowNode});
            // //       }

            // //       //if (col.colDef.hasOwnProperty('valueFormatter')) {
            // //       if (col.colDef.hasOwnProperty('cellRendererSelector')) {
            // //         //value = col.colDef.valueFormatter({ node: rowNode, value });

            // //         //const {ctrlsFactory} = col.context.contextParams.beanClasses
            // //         //const ctrlsFactory = col.context.contextParams.beanClasses.find(f => f.name == 'CtrlsService')
            // //         //console.log(col.context.contextParams.beanClasses)
            // //         //CtrlsFactory
            // //         //context.contextParams.providedBeanInstances.frameworkComponentWrapper.agGridReact.gridOptions.frameworkComponents

            // //         //providedBeanInstances.gridOptions.api.context.beanWrappers.beans.beanInstance.ctrlsFactory
                    
            // //         const ctrlsFactory = event.api.context.beanWrappers.beans.beanInstance.ctrlsFactory

            // //         const userComponentFactory = event.api.context.beanWrappers.beans.beanInstance.userComponentFactory

            // //         console.log('ctrlsFactory', ctrlsFactory)
            // //         console.log('userComponentFactory', userComponentFactory)
            // //         console.log('cellRendererSelector', col.colDef.cellRendererSelector)
            // //         //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance(col.colDef.cellRendererSelector.component))
            // //         console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance('customPinnedRowRenderer'))
            // //         console.log('userComponentFactory.gottenInstance', userComponentFactory.getCellRendererDetails(col.colDef.cellRendererSelector))
            // //         console.log(col.context.contextParams.beanClasses)

            // //         const compDetails = userComponentFactory.getCellRendererDetails(col.colDef, {...col.colDef.cellRendererSelector.params, value } )
            // //         console.log('compDetails', compDetails)

            // //         const [componentPromise] = await Promise.all([
            // //           compDetails.newAgStackInstance()
            // //         ])
            // //         //const componentPromise = await compDetails.newAgStackInstance();
            // //         console.log('componentPromise', componentPromise)
            // //         if (componentPromise) {
            // //           // componentPromise.then((result) => {
            // //           //   //console.log('result', result)
            // //           //   //console.log('innerHTML', result.reactElement.props.reactContainer.innerHTML)
            // //           //   console.log('innerText', result.reactElement.props.reactContainer.innerText)
            // //           //   value = result.reactElement.props.reactContainer.innerText

            // //           //   target[col.colId] = value;
            // //           // });

            // //           const result = await componentPromise
            // //           //console.log('result', result)
            // //           //console.log('innerHTML', result.reactElement.props.reactContainer.innerHTML)
            // //           console.log('innerText', result.reactElement.props.reactContainer.innerText)
            // //           value = result.reactElement.props.reactContainer.innerText

            // //           target[col.colId] = value;

            // //         }

                    
            // //         //.getGui()


            // //         //ctrlsFactory.
            // //         //col.colDef.cellRendererSelector

            // //         //console.log('contextParams', col.context.contextParams)

            // //         //console.log('ctrlsFactory', ctrlsFactory)

            // //         //console.log('ctrlsFactory', ctrlsFactory())

            // //         //const ctrl = ctrlsFactory.getInstance(col.colDef.cellRendererSelector)

            // //         //console.log('ctrl', ctrl)

            // //         //console.log('cellRendererSelector', col.colDef.cellRendererSelector({ node: rowNode, value }))
            // //       }

            // //       target[col.colId] = value;
            // //     }
            // //     else {
            // //       target[col.colId] = null
            // //     }
            // //   //})
            // //   }

            // //   console.log('push target', target)
            // //   formattedValues.push(target)
            // // });

            // console.log('formattedValues', formattedValues)

            // //var newValue = params.colDef.valueGetter(params);


          }}
          onFilterChanged ={(event) => {
            console.log('onFilterChanged')
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
          }}
          onGridReady={(event) => { 
            console.log('onGridReady')

            //binds the loadPrevious function to the "copy" of the button in header
            // let _window = window;
            // _window['agHandleClick'] = (event) => {
            //     event.preventDefault();
            //     event.stopPropagation();
            //     event.stopImmediatePropagation();
            //     event.currentTarget.blur()
            //     loadPrevious(event)
            // };

            //setGridApi(event.api)
            //setGridColumnApi(event.columnApi)
            console.log(event);
            event.api.sizeColumnsToFit()
            event.api.setPinnedBottomRowData(generatePinnedBottomData(event.api, event.columnApi))
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'Week',
                  sort: 'asc'
                }
              ]
            })
          }}
        >
        </AgGridReact>
      </div>
    </React.Fragment>
  )
}

export default WeeklySummary