//import authService from '../components/api-authorization/AuthorizeService'
import { useFetchWrapper } from '../services/fetch-wrapper'

export { useLeasingVelocityService };

const useLeasingVelocityService = () => {
  const fetchWrapper = useFetchWrapper()

  const GetLeasingVelocityData = async (params) => {
    const queryOptions = new URLSearchParams(params).toString();
  
    return fetchWrapper.get(`leasingvelocity/weekly?${queryOptions}`)
  }
  
  const GetFirstMonday = (date) => {
      date.setDate(1);
  
      // Get the first Monday in the month
      while (date.getDay() !== 1) {
        date.setDate(date.getDate() + 1);
      }

      return date;
  }

  const GetDefaultPeriod = () => {
    //const currentDate = new Date(2022, 9, 2)
    const currentDate = new Date()

    let startYear = currentDate.getFullYear();
    let pivotMonth = 9; //september
    console.log('current month ' + currentDate.getMonth())
    console.log('current date ', currentDate)
    console.log('first monday', GetFirstMonday(new Date(currentDate.getFullYear(), pivotMonth - 1, 1)))
    if (currentDate.getMonth() >= pivotMonth - 1
      && currentDate >= GetFirstMonday(new Date(currentDate.getFullYear(), pivotMonth - 1, 1)))
    {
      startYear++
    }

    return startYear;
}

  return { GetLeasingVelocityData, GetDefaultPeriod, GetFirstMonday }
}