import authService from '../components/api-authorization/AuthorizeService';
import { useFetchWrapper } from '../services/fetch-wrapper'

export { useUserService };

const useUserService = () => {

  const fetchWrapper = useFetchWrapper()

    const GetUserCurrentLocation = async () => {
        return fetchWrapper.get(`user/currentlocation`)
    }

    const UpdateUserCurrentLocation = async (locationId) => {
        return fetchWrapper.put(`user/currentlocation/${locationId}`)
    }

  const GetUserStatus = async () => {
    //we don't use fetchWrapper here because we need to allow redirect follow.
    const token = await authService.getAccessToken();
    const authHeader = !token ? {} : { 'Authorization': `Bearer ${token}` }

    const requestOptions = {
      headers: authHeader,
      redirect: 'follow'
    }

    fetch(`user/status`, requestOptions
    ).then(response => {
      // HTTP 301 response
      if (response.redirected) {
        window.location.href = response.url;
      }

      //console.log(response)
      if (response.ok) {
        return false;
      }
    }).catch((error) => { console.log(error); return error  })

    return false;
  }

    return { GetUserCurrentLocation, UpdateUserCurrentLocation, GetUserStatus }
}