export const DateFormatter = new Intl.DateTimeFormat('en-US', {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
});

export const ShortDateFormatter = new Intl.DateTimeFormat('en-US', {
  year: "numeric",
  month: "numeric",
  day: "numeric"
});

export const DateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric', month: '2-digit', day: '2-digit',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
});

export const dateTimeFormat = (params) => {
  try
  {
    if (params && params.value)
    {
      return DateTimeFormatter.format(new Date(params.value))
    }
    else {
      return ''
    }
  }
  catch
  {
    return ''
  }
}

export const dateFormat = (params) => {
  try
  {
    if (params && params.value)
    {
      return DateFormatter.format(new Date(params.value))
    }
    else {
      return ''
    }
  }
  catch
  {
    return ''
  }
}

export const dateFilter = () => {
  return {
    debounceMs: 500,
    buttons: ['reset', 'clear'],
    suppressAndOrCondition: true,
    inRangeInclusive: true,
    filterOptions: [
      'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange' 
    ],
    comparator: function(filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) {
        return 0;
      }
      var cellDate = new Date(cellValue);
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }
  }
}