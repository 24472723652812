import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useState } from 'react'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import { dateFormat, dateFilter } from '../../services/DateFormatter'
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent'

const CanceledLeases = ({innerRef : gridRef, CanceledLeasesData, isLoading}) => {

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  const [defaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'text-center',
    headerComponentParams: centeredHeaderComponentParams()
  })

  const getColumnDefs = () => {
    return [
      {
        field: 'AccountNumber',
        maxWidth: 120,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'FirstName',
        maxWidth: 150,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'LastName',
        maxWidth: 150,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'PropertyName',
        minWidth: 250,
        cellClass: 'text-start',
      },
      {
        field: 'UnitName',
        maxWidth: 130,
        cellClass: 'text-start',
      },
      {
        field: "CanceledDate",
        suppressSizeToFit: true,
        maxWidth: 150,
        filter: 'agDateColumnFilter',
        valueFormatter: dateFormat,
        filterParams: dateFilter
      },
      {
        colId: 'padding',
        resizable: false,
        headerName: ' ',
        minWidth: 30,
        sortable: false,
        filter: false
      }
    ]
  }
  const [columnDefs] = useState(getColumnDefs())

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  return (
    <React.Fragment>
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={CanceledLeasesData}
          rowSelection="none"
          paginationPageSize={30}
          columnDefs={columnDefs}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          defaultColDef={defaultColumnDefs}
          pagination={false}
          frameworkComponents={{
            customLoadingOverLoad: CustomLoadingOverlay,
          }}
          onGridReady={(event) => { 
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'CanceledDate',
                  sort: 'asc'
                }
              ]
            })
          }}
        >
        </AgGridReact>
      </div>
    </React.Fragment>
  )
}

export default CanceledLeases