import BaseSelect from "../dropdowns/BaseSelect";

export const PeriodSelect = ({onChange, onError, initialValue, ...rest}) => {
  return (
    <BaseSelect 
      serviceUrl="leasingvelocityperiod"
      valueName="End"
      labelName="Label"
      initialValue={initialValue}
      selectFirst={false}
      onChange={onChange}
      onError={onError}
      {...rest}
    />
  )
}

export const PropertyGroupSelect = ({onChange, onError, ...rest}) => {
  const params = new URLSearchParams({addAll: true}).toString();

  return (
    <BaseSelect
      serviceUrl={`propertygroup?${params}`}
      valueName="GroupID"
      labelName="Name"
      onChange={onChange}
      onError={onError}
      {...rest}
    />
  )
}

export const PropertySelect = ({onChange, onError, propertyGroup, ...rest}) => {
  const params = new URLSearchParams({propertyGroup, addAll: true}).toString();

  return (
      <BaseSelect
        serviceUrl={`property?${params}`}
        valueName="PropertyID"
        labelName="PropertyName"
        onChange={onChange}
        onError={onError}
        useEffectDependencies={[propertyGroup]}
        {...rest}
      />
  )
}