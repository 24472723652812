import React, { Component, Fragment, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { NavItem, NavLink, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { PersonCircle } from 'react-bootstrap-icons'
import LocationMenu from './LocationMenu';
import { useLocationService } from '../../services/LocationService';
import { useUserService } from '../../services/UserService';

export { LoginMenu }

const LoginMenu = (props) => {

    let _subscription = null

    const initialState = {
        isLoading: true,
        isAuthenticated: false,
        userName: null,
        userLocation: -1,
        userLocations: [],
    }

    const locationService = useLocationService()
    const userService = useUserService()
    const [state, setState] = useState(initialState)


    useEffect(() => {
        _subscription = authService.subscribe(() => populateState());
        populateState();

        // Specify how to clean up after this effect:
        return function cleanup() {
            authService.unsubscribe(_subscription);
        };
    }, []);

    const populateState = async () => {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser()
        ])

        let userLocation = state.userLocation;
        let userLocations = state.userLocations;
        if (isAuthenticated) {
            const [locations, userCurrentLocation] = await Promise.all([
                locationService.GetLocations(),
                userService.GetUserCurrentLocation()
            ])
            userLocation = userCurrentLocation
            userLocations = locations //.pop(), //test for 1
        }

        setState({
            isLoading: false,
            isAuthenticated,
            userName: user && user.name,
            userLocation,
            userLocations
        });
    }

    const render = () => {
        const { isAuthenticated, userName, userLocation, userLocations } = state;
        console.log('isAuthenticated: ', isAuthenticated)
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.ChangePassword}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return authenticatedView(userName, userLocation, userLocations, setUserLocation, profilePath, logoutPath);
        }
    }

    const renderMenuItem = (path, text, color, isLast = false) => {
        return (
            <DropdownItem className="m-0 p-0 profile-menu-item">
                <NavLink tag={Link} className="text-dark m-0 p-0" to={path}>
                    <div className={`d-flex flex-row align-items-center border border-${color} ${isLast ? 'border-top-0 rounded-bottom' : ''}`}>
                        <div className={`flex-shrink p-1 bg-${color}`}>&nbsp;</div>
                        <div className="flex-grow ps-2">{text}</div>
                    </div>
                </NavLink>
            </DropdownItem>
        )
    }

    const authenticatedView = (userName, userLocation, userLocations, setUserLocation, profilePath, logoutPath) => {
        return (
            <Fragment>
                <NavItem>
                  <NavLink tag={Link} className="text-white" to="/leasing-velocity">Leasing Velocity Report</NavLink>
                </NavItem>
                {
                    userLocations && userLocations.length > 1 && (
                        <LocationMenu
                            setUserLocation={setUserLocation}
                            userLocation={userLocation}
                            userLocations={userLocations}
                        />
                    )
                }
                <UncontrolledDropdown>
                    <DropdownToggle
                        data-toggle="dropdown"
                        className="btn btn-primary btn-circle"
                        tag="button"
                        caret
                    >
                        <PersonCircle />
                    </DropdownToggle>
                    <DropdownMenu end className="pb-0 pt-0 border-0">
                        <DropdownItem header className="text-white p-3 bg-primary border border-primary border-bottom-0 rounded-top">
                            <div className="d-flex flex-fill flex-column align-items-center" style={{minWidth: '250px'}}>
                                <PersonCircle size={40} />
                                <div className="mt-2">{userName}</div>
                            </div>
                        </DropdownItem>
                        {/* {renderMenuItem('/admin/settings', 'User Settings', 'light')} */}
                        {/*{renderMenuItem(profilePath, 'Change Password', 'light', true)}*/}
                        {renderMenuItem(logoutPath, 'Logout', 'light', true)}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
            </NavItem>
            </Fragment>
        );
    }

    const anonymousView = (registerPath, loginPath) => {
        return (<Fragment>
            {/* <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem> */}
            <NavItem>
                <NavLink tag={Link} to={loginPath}><Button color="primary" className="text-white p-3 pe-4"><PersonCircle /> Login</Button></NavLink>
            </NavItem>
        </Fragment>);
    }

    const setUserLocation = async (locationId) => {
        console.log('setUserLocation called with id: ' + locationId)
        if (state.userLocations && state.userLocations.find((location) => location.LocationID == locationId)) {
          console.log('setUserLocation called and set to: ' + locationId)
    
          var result = await userService.UpdateUserCurrentLocation(locationId)
          if (result.isSuccess) {
            const newLocation = await locationService.GetLocation(locationId)
    
            console.log('set location to retrieved value: ', newLocation)
      
            setState({
              ...state,
              userLocation: newLocation
            })

            window.location.reload();
          }
        }
      }
    

    return render()
}