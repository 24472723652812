import React, { useEffect } from 'react'
import { Redirect, Route, Switch, withRouter, useLocation } from 'react-router-dom'
import { Layout } from './components/Layout'
import Forbidden from './components/Forbidden'
import Error from './components/Error'
import { Home } from './components/Home'
import LeasingVelocity from './components/leasing-velocity/LeasingVelocity'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants'
import { ToastContainer, toast } from 'react-toastify';

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.css';
import './custom.scss'
import { useUserService } from './services/UserService'

const App = ({ history }) => {
  
  const userService = useUserService()

  const location = useLocation();

  useEffect(() => {
    App.displayName = App.name
    toast.configure()
    history.listen((listenerLocation, action) => {
      // do something on route change
      // for my example, close a drawer
      console.log(listenerLocation)
      console.log(listenerLocation.pathname)
      console.log(action)

      callRouteChanged(listenerLocation.pathname)
    })

    callRouteChanged(location.pathname)
  }, [])

  const onRouteChanged = async () => {
    console.log("ROUTE CHANGED")

    await userService.GetUserStatus()
  }

  const callRouteChanged = (pathname) => {
    switch(pathname) {
      case ApplicationPaths.Login:
      case ApplicationPaths.LoginFailed:
      case ApplicationPaths.LoginCallback:
      case ApplicationPaths.LogOut:
      case ApplicationPaths.LogOutCallback:
      case ApplicationPaths.LoggedOut:
        break;
      default:
        onRouteChanged()
    }    
  }

  return (
    <React.Fragment>
      {/* <AuthContextProvider>
        <UserPreferencesContextProvider> */}
          <Layout>
            <Switch>
              <Route path="/forbidden" component={Forbidden} />
              <Route path="/error" component={Error} />
              <Route exact path='/'>
                <Redirect to="/leasing-velocity" />
              </Route>
              <AuthorizeRoute path='/leasing-velocity' component={LeasingVelocity} />
              <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Switch>
            
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
          </Layout>
        {/* </UserPreferencesContextProvider>
      </AuthContextProvider> */}
    </React.Fragment>
  )
}

export default withRouter(App);