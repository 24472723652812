import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Route {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
            authToken: null,
            role: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated, authToken } = this.state;
        const isAuthenticated = !!authToken;
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, role, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if ((isAuthenticated && role && this.state.role && (this.state.role === role || this.state.role.includes(role)))
                            || (isAuthenticated && !role)) {
                        return <Component {...props} />
                    } else if (isAuthenticated && role && (this.state.role !== role && (this.state.role && !this.state.role.includes(role)))) {
                        return <Redirect to="/forbidden" />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const authToken = await authService.getAccessToken();
        this.setState({ ready: true, authenticated, authToken });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false, authToken: null });
        await this.populateAuthenticationState();
    }
}
