import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useState } from 'react'
import { CustomLoadingOverlay } from '../widget/AGGridCustomOverlay'
import { dateFormat, dateFilter } from '../../services/DateFormatter'
import { centeredHeaderComponentParams } from '../widget/CenteredHeaderComponent'

const NewLeases = ({innerRef : gridRef, NewLeasesData, isLoading}) => {

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideOverlay()
    }
  }, [isLoading])

  const [defaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'text-center',
    headerComponentParams: centeredHeaderComponentParams()
  })

  const getColumnDefs = () => {
    return [
      {
        field: 'AccountNumber',
        maxWidth: 120,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'FirstName',
        maxWidth: 150,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'LastName',
        maxWidth: 150,
        cellClass: 'text-start',
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'PropertyName',
        minWidth: 250,
        cellClass: 'text-start',
      },
      {
        field: 'UnitName',
        maxWidth: 130,
        cellClass: 'text-start',
      },
      {
        field: "MoveInDate",
        //headerName: "Move-In Date",
        headerClass: 'text-nowrap',
        suppressSizeToFit: true,
        maxWidth: 150,
        filter: 'agDateColumnFilter',
        valueFormatter: dateFormat,
        filterParams: dateFilter,
        headerComponentParams: {
          template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span class="ag-header-cell-text" role="columnheader" style="white-space: normal; display: inline-block; max-width: min-content; text-align: center; margin: auto;">Move&#8209;In Date</span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>'
        }
      },
      {
        field: "StartDate",
        suppressSizeToFit: true,
        maxWidth: 130,
        filter: 'agDateColumnFilter',
        valueFormatter: dateFormat,
        filterParams: dateFilter
      },
      {
        field: "EndDate",
        suppressSizeToFit: true,
        maxWidth: 130,
        filter: 'agDateColumnFilter',
        valueFormatter: dateFormat,
        filterParams: dateFilter
      },
      {
        colId: 'padding',
        resizable: false,
        headerName: ' ',
        minWidth: 30,
        sortable: false,
        filter: false
      }
    ]
  }
  const [columnDefs] = useState(getColumnDefs())

  const onShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, [])

  const onHideOverlay = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  return (
    <React.Fragment>
      <div className="ag-theme-alpine" style={{height: '95vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={NewLeasesData}
          rowSelection="none"
          paginationPageSize={30}
          columnDefs={columnDefs}
          loadingOverlayComponent="customLoadingOverLoad"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          defaultColDef={defaultColumnDefs}
          pagination={false}
          frameworkComponents={{
            customLoadingOverLoad: CustomLoadingOverlay,
          }}
          onGridReady={(event) => { 
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'StartDate',
                  sort: 'asc'
                }
              ]
            })
          }}
        >
        </AgGridReact>
      </div>
    </React.Fragment>
  )
}

export default NewLeases