import React, { Component } from 'react';
import NumberFormat from 'react-number-format'

export default class WeeklySummaryPinnerRowRenderer extends Component {
  render() {
    {
      if (this.props.format === 'currency') {
        return (<NumberFormat value={this.props.value} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} />)
      }
      else if (this.props.format === 'integer') {
        return (<NumberFormat value={this.props.value} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true}  prefix={''} />)
      }
      else if (this.props.format === 'percent') {
        return (<NumberFormat value={this.props.value} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={1} fixedDecimalScale={true} suffix={'%'} />)
      }
      else {
        return (<span style={this.props.style}>{this.props.value}</span>)
      }
    }
  }
}