import custom from '../../custom.scss'

export const customStyles = {
  option: (provided, state) => {
    //console.log('control', provided)
    return ({
      ...provided,
      backgroundColor: state.isSelected ? custom.dark : 'transparent',
      "&:hover": {
        backgroundColor: state.isSelected ? custom.dark : custom.light
      }
    })
  },
  control: (provided, state) => {
    return ({
      ...provided,
      border: state.isFocused ? `${custom.borderWidth} solid ${custom.light}` : `${custom.borderWidth} solid ${custom.borderColor}`,
      boxShadow: state.isFocused ? custom.inputFocusBoxShadow : "none",
      "&:hover": {
        border: state.isFocused ? `${custom.borderWidth} solid ${custom.light}` : `${custom.borderWidth} solid ${custom.borderColor}`,
      }
    })
  },
  menu: (provided, state) => {
    return ({
      ...provided,
      zIndex: 20
    })
  }
 };