import { useCallback, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import authService from '../components/api-authorization/AuthorizeService';
import { QueryParameterNames, ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { toast } from 'react-toastify';

export { useFetchWrapper };

function useFetchWrapper() {
    //const [auth, setAuth] = useRecoilState(authAtom);
    // const [isReady, setIsReady] = useState(false)
    // const [isAuthenticated, setIsAuthenticated] = useState(false)
    // const [authToken, setAuthToken] = useState(null)
    const history = useHistory();

    // useEffect(() => {
    //   setFunctions({
    //     get: request('GET'),
    //     post: request('POST'),
    //     put: request('PUT'),
    //     delete: request('DELETE')
    //   })
    // }, [authToken])

    // useEffect(() => {
    //   populateState()
    // }, [])

    //const get = useCallback(() => { await request('GET') }, [authToken]);
    // const get = () => request('GET')

    // const get = useCallback(function() {
    //   request('GET');
    // }, [authToken])

    // const post = useCallback(function() {
    //   request('POST');
    // }, []);

    // const put = useCallback(function() {
    //   request('PUT');
    // }, []);

    // const del = useCallback( function() {
    //   request('DELETE');
    // }, []);

    // const populateState = async () => {
    //   console.log('Entering populate state')
    //   const [isAuthenticated, authToken] = await Promise.all([authService.isAuthenticated(), authService.getAccessToken()])
    //   console.log(authToken)
    //   console.log(isAuthenticated)
    //   setIsAuthenticated(isAuthenticated)
    //   setAuthToken(authToken)
    //   console.log('setting is ready to true')
    //   setIsReady(true)
    // }

    // await populateState().then(() => { return {
    //   get: request('GET'),
    //   post: request('POST'),
    //   put: request('PUT'),
    //   delete: request('DELETE')
    // }})

    const request = (method) => {
      return async (url, body) => {
        //const [isAuthenticated, authToken] = await Promise.all([authService.isAuthenticated(), authService.getAccessToken()])
        const authToken = await authService.getAccessToken()

          const requestOptions = {
              method,
              headers: authHeader(url, authToken)
          };
          requestOptions.headers['redirect'] = 'follow';
          if (body) {
              requestOptions.headers['Content-Type'] = 'application/json';
              requestOptions.body = JSON.stringify(body);
          }

          return fetch(url, requestOptions).then((response) => handleResponse(response, authToken));
      }
  }
    
    // helper functions
    
    function authHeader(url, authToken) {
        // return auth header with jwt if user is logged in and request is to the api url
        //const [isAuthenticated, authToken] = await Promise.all([authService.isAuthenticated(), authService.getAccessToken()])
        //Promise.all([authService.isAuthenticated(), authService.getAccessToken()]).then(([isAuthenticated, authToken]) => {

          const isLoggedIn = !!authToken;
          //const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);

          //console.log('isLoggedIn: ', isLoggedIn)
          //console.log('isApiUrl: ', isApiUrl)

          if (isLoggedIn) { //} && isApiUrl) {
            //console.log('returning auth bearer')
            //console.log({ 'Authorization': `Bearer ${authToken}` })
              return { 'Authorization': `Bearer ${authToken}` };
          } else {
              return {};
          }
        //});
    }
    
    function handleResponse(response, authToken) {
        return new Promise((resolve, reject) => { 
        return response.text().then(text => {
    
        let data = undefined
        try
        {
            data = text && JSON.parse(text);
        }
        catch
        {
            data = { message: "Unexpected response: " + response.status }
        }

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                //localStorage.removeItem('user');
                //setAuth(null);
                //history.push('/account/login');
                //var link = document.createElement("a");
                const returnUrl = window.location.href;
                //const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
                const loginReturnUrl = `${window.location.origin}/${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
                //link.remove();

                console.log(returnUrl)
                console.log(loginReturnUrl)

                //const returnUrl = `?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`
                const logoutPath = {
                    pathname: `${ApplicationPaths.LogOut}`,
                    search: `?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(loginReturnUrl)}`,
                    state: { local: true }
                };
                history.push(logoutPath)

                data = { message: "Login Necessary" + response.status }
            }
            else if (response.redirected) {
                data = { message: "Redirecting" + response.status }
                window.location.href = response.url;
            }

            const error = (data && data.message) || (data && data.title) || response.statusText;
            toast.error(error, {
                toastId: 'fetch-wrapper-toast-id',
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            })
            return reject(error);
        }

        return resolve(data);
    })})
        
//        });
    }

    //return {get, post, put, del}
    //return functions
    //return { get }
    return {
          get: request('GET'),
          post: request('POST'),
          put: request('PUT'),
          delete: request('DELETE')
        }
}