import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { ArrowClockwise, FileExcelFill, FileSpreadsheetFill } from 'react-bootstrap-icons'
import classnames from 'classnames'
import { Button, ButtonGroup, Col, Container, Form, FormGroup, Label, NavLink, Row, Nav, NavItem, TabContent, TabPane } from 'reactstrap'
import { PeriodSelect, PropertyGroupSelect, PropertySelect } from './LeasingVelocityDropDowns'
import { useLeasingVelocityService } from '../../services/LeasingVelocityService'
import WeeklySummary from './WeeklySummary'
import PropertySummary from './PropertySummary'
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid'
import * as XLSX from "xlsx";
import { All } from '../dropdowns/DefaultSelectOptions'
import RenewalLeases from './RenewalLeases'
import NewLeases from './NewLeases'
import TransferLeases from './TransferLeases'
import CanceledLeases from './CanceledLeases'

const Tabs = {
  WeeklySummary: 'weeklySummary',
  PropertySummary: 'propertySummary',
  RenewalLeases: 'renewalLeases',
  NewLeases: 'newLeases',
  TransferLeases: 'transferLeases',
  CanceledLeases: 'canceledLeases',
  BullitinBoard: 'bullitinBoard'
}

const LeasingVelocity = () => {

  const propertySummaryGridRef = useRef()
  const weeklySummaryGridRef = useRef()
  const renewalLeasesGridRef = useRef()
  const newLeasesGridRef = useRef()
  const transferLeasesGridRef = useRef()
  const canceledLeasesGridRef = useRef()

  const getLabelByEndYear = (endYear) => {
    return (endYear - 1).toString().substring(2, 4) + '/' + endYear.toString().substring(2, 4)
  }

  const leasingVelocityService = useLeasingVelocityService();
  
  const initialState = {
    isLoading: false,
    activeTab: Tabs.WeeklySummary,
    displayOptions: 'hidden',
    propertyGroup: All.value,
    propertyGroupName: '',
    property: All.value,
    propertyName: '',
    period: leasingVelocityService.GetDefaultPeriod(),
    periodLabels: [],
    filterStateId: nanoid()
  }

  const initialPeriodLabels = [getLabelByEndYear(initialState.period + 1), getLabelByEndYear(initialState.period)];

  const initialData = {
    WeeklyData: [],
    PropertyData: [],
    RenewalLeasesData: [],
    NewLeasesData: [],
    TransferLeasesData: [],
    CanceledLeasesData: [],
    Header: undefined,
    UnitCount: undefined,
    DataStateId: initialState.filterStateId
  }

  const stateReducer = (state, action) => {
    console.log(action)

    let filterStateId = state.filterStateId
    if (((action.name === 'propertyGroup' || action.name === 'property' && state[action.name] !== action.value.value) || (action.name === 'period' && state[action.name] !== action.value))) {
      console.log('assign new nanoid')
      filterStateId = nanoid()
    }

    switch (action.type) {
      case 'SET': {
        if (action.name === 'isLoading' && action.value)
        {
          //console.log('updating gridkey!!')
          return {
            ...state,
            [action.name]: action.value,
            filterStateId
            //gridKey: nanoid()
          }
        }
        else {
          return {
            ...state,
            [action.name]: action.value,
            filterStateId
          }
        }
      }
      case 'SET_OPTION': {
        return {
          ...state,
          [action.name]: action.value.value,
          [action.name + 'Name']: action.value.label,
          filterStateId
        }
      }
    }

    return state
  }

  const [state, dispatch] = useReducer(stateReducer, initialState)
  const [weeklySummaryData, setWeeklySummaryData] = useState(initialData)

  // useEffect(() => {
  //   load()
  // }, [])

  const load = async (e) => {
    //console.log('load displayOptions', e)

    if (e.hasOptions) {
      dispatch({ type: 'SET', name: 'displayOptions', value: 'visible' })
    }
  }

  const onDataLoadError = (response) => {
    console.log('----------------------')
    console.log(response)
  }

  const onRefresh = (/*e*/) => {
    console.log('Refresh data!')
    onShowLoading()
    populateData()
  }

  const onExport = useCallback( async () => {
    /* get state data and export to XLSX */

    let error = ''
    if (!state.periodLabels || state.periodLabels.length === 0
      || !weeklySummaryData.WeeklyData || weeklySummaryData.WeeklyData.length === 0
      || !weeklySummaryData.PropertyData || weeklySummaryData.PropertyData.length === 0) {

        error = 'No data loaded.';
    } else if (state.filterStateId !== weeklySummaryData.DataStateId) {
      error = 'Filters have changed since loading the data. Please reload before exporting.';
    }

    if (error !== '') {
      toast.warning(error, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
      })

      return
    }

    const weeklyData = await extractGridData(weeklySummaryGridRef.current)
    const propertyData = await extractGridData(propertySummaryGridRef.current)
    const renewalLeasesData = await extractGridData(renewalLeasesGridRef.current)
    const newLeasesData = await extractGridData(newLeasesGridRef.current)
    const transferLeasesData = await extractGridData(transferLeasesGridRef.current)
    const canceledLeasesData = await extractGridData(canceledLeasesGridRef.current)

    console.log('extracted weeklyData', weeklyData)
    console.log('extracted propertyData', propertyData)
    console.log('extracted renewalLeasesData', renewalLeasesData)
    console.log('extracted newLeasesData', newLeasesData)
    console.log('extracted transferLeasesData', transferLeasesData)
    console.log('extracted canceledLeasesData', canceledLeasesData)
    

    // console.log(state.periodLabels)
    // console.log(weeklySummaryData.WeeklyData)
    //console.log(weeklySummaryData.PropertyData)
    // console.log(state.propertyGroup, state.propertyGroupName)
    // console.log(state.property, state.propertyName)

    // weeklySummaryData.PropertyData.forEach((x) => {
    //   if (x.hasOwnProperty('PropertyShortName')) {
    //     delete x.PropertyShortName
    //   }
    // });
    
    weeklyData.forEach((x) => delete x.paddingChild);
    renewalLeasesData.forEach((x) => delete x.padding);
    newLeasesData.forEach((x) => delete x.padding);
    transferLeasesData.forEach((x) => delete x.padding);
    canceledLeasesData.forEach((x) => delete x.padding);
    

    //const sortedHeader = ['clientName', 'projectName', 'sourceLanguageName', 'targetLanguageName', 'numItemsTarget', 'numItemsWithoutImages', 'numWordsCreative', 'numWordsNonCreative'];

    const workBook = XLSX.utils.book_new();
    //const weeklyDataWorkSheet = XLSX.utils.json_to_sheet(weeklySummaryData.WeeklyData)
    const weeklyDataWorkSheet = XLSX.utils.json_to_sheet(weeklyData)
    //const propertySummaryWorkSheet = XLSX.utils.json_to_sheet(weeklySummaryData.PropertyData)
    const propertySummaryWorkSheet = XLSX.utils.json_to_sheet(propertyData)
    const renewalLeasesWorkSheet = XLSX.utils.json_to_sheet(renewalLeasesData)
    const newLeasesWorkSheet = XLSX.utils.json_to_sheet(newLeasesData)
    const transferLeasesWorkSheet = XLSX.utils.json_to_sheet(transferLeasesData)
    const canceledLeasesWorkSheet = XLSX.utils.json_to_sheet(canceledLeasesData)
   
    const period = state.periodLabels[0].replace('/', '-')
    const group = state.propertyGroupName
    const hasProperty = state.property !== All.value
    const property = hasProperty ? '--' + state.propertyName : ''

    const now = new Date()//.toLocaleDateString()
    const year = now.getFullYear()
    const month = now.getMonth().toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')
    const hours = now.getHours().toString().padStart(2, '0')
    const minutes = now.getMinutes().toString().padStart(2, '0')
    const AmPm = hours < 12 ? 'AM' : 'PM'
    const fileTimeStamp = `${month}-${day}-${year}_${hours}${minutes}${AmPm}`

    XLSX.utils.book_append_sheet(workBook, weeklyDataWorkSheet, "Weekly Summary")
    XLSX.utils.book_append_sheet(workBook, propertySummaryWorkSheet, "Property Summary")
    XLSX.utils.book_append_sheet(workBook, renewalLeasesWorkSheet, "Renewal Leases")
    XLSX.utils.book_append_sheet(workBook, newLeasesWorkSheet, "New Leases")
    XLSX.utils.book_append_sheet(workBook, transferLeasesWorkSheet, "Transfer Leases")
    XLSX.utils.book_append_sheet(workBook, canceledLeasesWorkSheet, "Canceled Leases")
    XLSX.writeFileXLSX(workBook, `LeasingVelocityExport_${group}${property}_[${period}]_${fileTimeStamp}.xlsx`)
  }, [weeklySummaryData.WeeklyData, weeklySummaryData.PropertyData, state.periodLabels,
    state.propertyGroupName, state.propertyName])

  const extractGridData = async ({api, columnApi}) => {
    const formattedValues = []
    const gridColumns = columnApi.getAllGridColumns()
    api.forEachNode((rowNode) => {
      let target = {}
      for (let j = 0; j < gridColumns.length; j++) {
        const col = gridColumns[j]

        if (rowNode.data[col.colId]) {
          target[col.colId] = rowNode.data[col.colId]

          if (col.colDef.hasOwnProperty('valueGetter')) {
            target[col.colId] = col.colDef.valueGetter({ node: rowNode })
          }

          if (col.colDef.hasOwnProperty('valueFormatter')) {
            target[col.colId] = col.colDef.valueFormatter({ node: rowNode, value: target[col.colId]})
          }
        }
        else {
          target[col.colId] = null
        }
      }
      formattedValues.push(target)
    })

    for (let i = 0; i < formattedValues.length; i++) {
      const promisses = []

      for (let j = 0; j < gridColumns.length; j++) {
        const col = gridColumns[j]
        //console.log('col', col)
        if (col.colDef.hasOwnProperty('cellRendererSelector')) {
          const userComponentFactory = api.context.beanWrappers.beans.beanInstance.userComponentFactory

          //console.log('ctrlsFactory', ctrlsFactory)
          //console.log('userComponentFactory', userComponentFactory)
          //console.log('cellRendererSelector', col.colDef.cellRendererSelector)
          //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance(col.colDef.cellRendererSelector.component))
          //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance('customPinnedRowRenderer'))
          //console.log('userComponentFactory.gottenInstance', userComponentFactory.getCellRendererDetails(col.colDef.cellRendererSelector))
          //console.log(col.context.contextParams.beanClasses)

          const compDetails = userComponentFactory.getCellRendererDetails(col.colDef, {...col.colDef.cellRendererSelector.params, value: formattedValues[i][col.colId] } )
          //console.log('compDetails', compDetails)

          promisses.push(compDetails.newAgStackInstance())

          // const [componentPromise] = await Promise.all([
          //   compDetails.newAgStackInstance()
          // ])
          //const componentPromise = await compDetails.newAgStackInstance();
          //console.log('componentPromise', componentPromise)

          // if (componentPromise) {
          //   // componentPromise.then((result) => {
          //   //   //console.log('result', result)
          //   //   //console.log('innerHTML', result.reactElement.props.reactContainer.innerHTML)
          //   //   console.log('innerText', result.reactElement.props.reactContainer.innerText)
          //   //   value = result.reactElement.props.reactContainer.innerText

          //   //   target[col.colId] = value;
          //   // });

          //   //const result = await componentPromise
          //   //console.log('result', result)
          //   //console.log('innerHTML', result.reactElement.props.reactContainer.innerHTML)
          //   //console.log('innerText', componentPromise.reactElement.props.reactContainer.innerText)
          //   formattedValues[i][col.colId] = componentPromise.reactElement.props.reactContainer.innerText;
          // }
        }
      }
      
      const results = await Promise.all(promisses)
      let currentResultIndex = 0
      for (let j = 0; j < gridColumns.length; j++) {
        const col = gridColumns[j]

        if (col.colDef.hasOwnProperty('cellRendererSelector')) {
          const componentPromise = results[currentResultIndex]
          if (componentPromise) {
            formattedValues[i][col.colId] = componentPromise.reactElement.props.reactContainer.innerText;
          }

          currentResultIndex++
        }
      }
    }

    //this code doesn't work because of the promise returned by tge component cannot be awaited in the foreach.
    // const formattedValues = []
    // const gridColumns = columnApi.getAllGridColumns()
    // api.forEachNode((rowNode) => {
    //   let target = {}
    //   for (const col of gridColumns) {
    //   //gridColumns.forEach((col) => {

    //     if (rowNode.data[col.colId]) {
    //       //console.log(col)
    //       let value = rowNode.data[col.colId]
    //       if (col.colDef.hasOwnProperty('valueGetter')) {
    //         //console.log('rowNode', rowNode)
    //         value = col.colDef.valueGetter({ node: rowNode});
    //       }

    //       //if (col.colDef.hasOwnProperty('valueFormatter')) {
    //       if (col.colDef.hasOwnProperty('cellRendererSelector')) {
    //         //value = col.colDef.valueFormatter({ node: rowNode, value });

    //         //const {ctrlsFactory} = col.context.contextParams.beanClasses
    //         //const ctrlsFactory = col.context.contextParams.beanClasses.find(f => f.name == 'CtrlsService')
    //         //console.log(col.context.contextParams.beanClasses)
    //         //CtrlsFactory
    //         //context.contextParams.providedBeanInstances.frameworkComponentWrapper.agGridReact.gridOptions.frameworkComponents

    //         //providedBeanInstances.gridOptions.api.context.beanWrappers.beans.beanInstance.ctrlsFactory
            
    //         //const ctrlsFactory = api.context.beanWrappers.beans.beanInstance.ctrlsFactory

    //         const userComponentFactory = api.context.beanWrappers.beans.beanInstance.userComponentFactory

    //         //console.log('ctrlsFactory', ctrlsFactory)
    //         //console.log('userComponentFactory', userComponentFactory)
    //         //console.log('cellRendererSelector', col.colDef.cellRendererSelector)
    //         //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance(col.colDef.cellRendererSelector.component))
    //         //console.log('ctrlsFactory.gottenInstance', ctrlsFactory.getInstance('customPinnedRowRenderer'))
    //         //console.log('userComponentFactory.gottenInstance', userComponentFactory.getCellRendererDetails(col.colDef.cellRendererSelector))
    //         //console.log(col.context.contextParams.beanClasses)

    //         const compDetails = userComponentFactory.getCellRendererDetails(col.colDef, {...col.colDef.cellRendererSelector.params, value } )
    //         //console.log('compDetails', compDetails)

    //         const componentPromise = compDetails.newAgStackInstance();
    //         if (componentPromise) {
    //           componentPromise.then((result) => {
    //             value = result.reactElement.props.reactContainer.innerText

    //             console.log(result)
    //             //result.componentInstance.destroy()
    //             //result.reactElement.props.reactContainer.remove()
    //           });
    //         }
    //       }

    //       target[col.colId] = value;
    //     }
    //     else {
    //       target[col.colId] = null
    //     }
    //   //})
    //   }

    //   console.log('push target', target)
    //   formattedValues.push(target)
    // });

    // //console.log('formattedValues', formattedValues)

    return formattedValues
  }

  const onShowLoading = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: true })
  }

  const onHideOverlay = () => {
    dispatch({ type: 'SET', name: 'isLoading', value: false })
  }

  const populateData = async () => {
    const request = { 
      propertyGroup: state.propertyGroup,
      property: state.property,
      period: state.period
    }

    console.log('end year: ', state.period)

    const periodLabels = [getLabelByEndYear(state.period + 1), getLabelByEndYear(state.period)];
    console.log(periodLabels)
    dispatch({ type: 'SET', name: 'periodLabels', value: periodLabels })
    //state.periodLabels[0] = getLabelByEndYear(state.period)
    //state.periodLabels[1] = getLabelByEndYear(state.period + 1)

    const data = await leasingVelocityService.GetLeasingVelocityData(request);

    data.DataStateId = state.filterStateId

    console.log(data)
    setWeeklySummaryData(data);
    onHideOverlay()
  }

  const toggle = (tab) => {
    if (state.activeTab !== tab) {
      dispatch({ type: 'SET', name: 'activeTab', value: tab })
    }
  }

  return (
    <Container>
      <Row style={{visibility: state.displayOptions}}>
        <Col xs={12}>
          <Form>
            <Row>
              <Col md={12} lg={6}>
                <FormGroup row>
                  <Label htmlFor="propertyGroup" sm={12} md={2}>Group:</Label>
                  <Col sm={12} md={10}>
                    <PropertyGroupSelect
                      id="propertyGroup" 
                      name="propertyGroup"
                      onError={onDataLoadError}
                      //onLoaded={value => dispatch({ type: 'LOADED', name: 'propertyGroup', value })}
                      onChange={selected => dispatch({ type: 'SET_OPTION', name: 'propertyGroup', value: selected })}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6}>
                <FormGroup row>
                  <Label htmlFor="property" sm={12} md={2}>Property:</Label>
                  <Col sm={12} md={10}>
                    <PropertySelect
                      id="property" 
                      name="property"
                      propertyGroup={state.propertyGroup}
                      onError={onDataLoadError}
                      onLoaded={load}
                      onChange={selected => dispatch({ type: 'SET_OPTION', name: 'property', value: selected })}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6}>
                <FormGroup row>
                  <Label htmlFor="period" sm={12} md={2}>Period:</Label>
                  <Col sm={12} md={4}>
                    <PeriodSelect
                      id="period"
                      name="period"
                      initialValue={state.period}
                      //onLoaded={value => dispatch({ type: 'LOADED', name: 'period', value })}
                      onChange={selected => dispatch({ type: 'SET', name: 'period', value: selected.value })}
                    />
                  </Col>
                  <Col sm={12} md={6} className="text-end">
                    <Button color='success' onClick={onExport} className='me-2 mt-2 mt-md-0'><FileSpreadsheetFill /> Export</Button>
                    <Button color="secondary" onClick={onRefresh} className='mt-2 mt-md-0'><ArrowClockwise /> Refresh</Button>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        
          <Row className='mt-3'>
            <Col>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: state.activeTab === Tabs.WeeklySummary })}
                    onClick={() => { toggle(Tabs.WeeklySummary); }}
                  >
                    Weekly Summary
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: state.activeTab === Tabs.PropertySummary })}
                    onClick={() => { toggle(Tabs.PropertySummary); }}
                  >
                    Property Summary
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: state.activeTab === Tabs.RenewalLeases })}
                    onClick={() => { toggle(Tabs.RenewalLeases); }}
                  >
                    Renewal Leases
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: state.activeTab === Tabs.NewLeases })}
                    onClick={() => { toggle(Tabs.NewLeases); }}
                  >
                    New Leases
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: state.activeTab === Tabs.TransferLeases })}
                    onClick={() => { toggle(Tabs.TransferLeases); }}
                  >
                    Transfer Leases
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: state.activeTab === Tabs.CanceledLeases })}
                    onClick={() => { toggle(Tabs.CanceledLeases); }}
                  >
                    Canceled Leases
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={state.activeTab}>
                <TabPane tabId={Tabs.WeeklySummary}>
                  <div className='m-3'>
                    <Row>
                      <Col className={`text-end mt-3 mb-1 ${weeklySummaryData.UnitCount ? 'visible' : 'invisible'}`}><h5>Units ({weeklySummaryData && weeklySummaryData.UnitCount})</h5></Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {/* <h4>Tab 1 Contents</h4> */}
                        <WeeklySummary
                          innerRef={weeklySummaryGridRef}
                          isLoading={state.isLoading}
                          WeeklyData={weeklySummaryData.WeeklyData}
                          UnitCount={weeklySummaryData.UnitCount}
                          Header={weeklySummaryData.Header}
                          Headers={state.periodLabels}
                          InitialHeaders={initialPeriodLabels}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId={Tabs.PropertySummary}>
                <div className='m-3'>
                    <Row>
                      <Col className={`text-end mt-3 mb-1 ${weeklySummaryData.UnitCount ? 'visible' : 'invisible'}`}><h5>Units ({weeklySummaryData && weeklySummaryData.UnitCount})</h5></Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {/* <h4>Tab 1 Contents</h4> */}
                        <PropertySummary
                          innerRef={propertySummaryGridRef}
                          isLoading={state.isLoading}
                          PropertyData={weeklySummaryData.PropertyData}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId={Tabs.RenewalLeases}>
                  <div className='m-3'>
                    <Row>
                      <Col className={`text-end mt-3 mb-1 ${weeklySummaryData.UnitCount ? 'visible' : 'invisible'}`}><h5>Renewals ({weeklySummaryData && weeklySummaryData.RenewalLeasesData && weeklySummaryData.RenewalLeasesData.length})</h5></Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {/* <h4>Tab 1 Contents</h4> */}
                        <RenewalLeases
                          innerRef={renewalLeasesGridRef}
                          isLoading={state.isLoading}
                          RenewalLeasesData={weeklySummaryData.RenewalLeasesData}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId={Tabs.NewLeases}>
                  <div className='m-3'>
                    <Row>
                      <Col className={`text-end mt-3 mb-1 ${weeklySummaryData.UnitCount ? 'visible' : 'invisible'}`}><h5>New Leases ({weeklySummaryData && weeklySummaryData.NewLeasesData && weeklySummaryData.NewLeasesData.length})</h5></Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {/* <h4>Tab 1 Contents</h4> */}
                        <NewLeases
                          innerRef={newLeasesGridRef}
                          isLoading={state.isLoading}
                          NewLeasesData={weeklySummaryData.NewLeasesData}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId={Tabs.TransferLeases}>
                  <div className='m-3'>
                    <Row>
                      <Col className={`text-end mt-3 mb-1 ${weeklySummaryData.UnitCount ? 'visible' : 'invisible'}`}><h5>Transfer Leases ({weeklySummaryData && weeklySummaryData.TransferLeasesData && weeklySummaryData.TransferLeasesData.length})</h5></Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {/* <h4>Tab 1 Contents</h4> */}
                        <TransferLeases
                          innerRef={transferLeasesGridRef}
                          isLoading={state.isLoading}
                          TransferLeasesData={weeklySummaryData.TransferLeasesData}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId={Tabs.CanceledLeases}>
                  <div className='m-3'>
                    <Row>
                      <Col className={`text-end mt-3 mb-1 ${weeklySummaryData.UnitCount ? 'visible' : 'invisible'}`}><h5>Canceled Leases ({weeklySummaryData && weeklySummaryData.CanceledLeasesData && weeklySummaryData.CanceledLeasesData.length})</h5></Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {/* <h4>Tab 1 Contents</h4> */}
                        <CanceledLeases
                          innerRef={canceledLeasesGridRef}
                          isLoading={state.isLoading}
                          CanceledLeasesData={weeklySummaryData.CanceledLeasesData}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default LeasingVelocity